import React from 'react';
import styled, { css } from 'styled-components';
import Container from '../../../../src/common/FlexBox/Container';
import Row from '../../../../src/common/FlexBox/Row';
import Column from '../../../../src/common/FlexBox/Column';
import Typography from '../../../../src/common/Typography';
import Button from '../../../../src/common/Button';
import SvgComponent from '../../../../src/common/SvgComponent';
import {
  quikrLinks,
  quikrPolicies,
  quikrBrands,
  footerCities,
  socialLinks,
  appLinks
} from '../../../../src/helpers/footer-constants';

import footerSprite from '../../../../src/assets/footer-sprite.svg';

const FooterList = styled.ul`
  border-bottom: ${({ theme }) => theme.colors.Neutral[300]} solid 1px;
  @media screen and (min-width: 768px) {
    border: 0;
    padding: 0;
  }
`;
const Li = styled.li`
  margin: 0 15px 16px 0;
  &:last-child {
    margin-right: 0;
    &:after {
      display: none;
    }
  }
  @media screen and (min-width: 768px) {
    margin-right: 20px;
  }
  ${({ brand }) =>
    brand &&
    css`
      margin-right: 0;
      display: flex;
      align-items: center;
      @media screen and (min-width: 768px) {
        margin-right: 0;
      }
      &:after {
        content: '|';
        margin: 0 20px;
        color: ${({ theme }) => theme.colors.Neutral[100]};
      }
    `};
`;
const Link = styled.a`
  color: ${({ theme }) => theme.colors.TextAlpha};
  &:hover {
    color: ${({ theme }) => theme.colors.Primary};
  }
`;
const PostAdDiv = styled.div`
  background: ${({ theme }) => theme.colors.Neutral[500]};
  border-radius: 8px;
  padding: 20px 80px;
  display: none;
  span {
    margin-right: 20px;
  }
  @media screen and (min-width: 768px) {
    display: block;
  }
`;
const PostAd = styled(Button)`
  padding: 0 19px;
  height: 40px;
  border-width: 1px;
  color: ${({ theme }) => theme.colors.TextAlpha};
  align-items: center;
`;
const P = styled(Typography)`
  margin: 32px 0px;
  color: ${({ theme }) => theme.colors.TextBeta};
  line-height: 24px;
  display: none;
  text-align: justify;
  @media screen and (min-width: 768px) {
    display: block;
  }
`;
const Heading = styled(Typography)`
  margin: 32px 0px 20px;
  @media screen and (max-width: 767px) {
    margin: 22px 0px 15px;
  }
`;
const FooterWrap = styled(Container)`
  padding: 0 12px;
  border-top: ${({ theme }) => theme.colors.Neutral[300]} solid 1px;
  margin-bottom: 60px;
  margin-top: 32px;
  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
`;
const RowFot = styled(Row)`
  padding: 10px 0 24px;
  margin: 0 -12px;
  @media screen and (min-width: 768px) {
    padding: 24px 0;
    margin-left: -30px;
    margin-right: -30px;
  }
`;
const LeftColumn = styled(Column)`
  @media screen and (min-width: 768px) {
    padding: 0 30px;
    border-right: 1px solid ${({ theme }) => theme.colors.Neutral[300]};
  }
  &::after {
    width: 1px;
    position: absolute;
    height: 100%;
    background: ${({ theme }) => theme.colors.Neutral[500]};
    top: 0;
    right: 0;
  }
`;
const RightColumn = styled(Column)`
  @media screen and (min-width: 768px) {
    padding: 0 30px;
  }
`;

const CityUl = styled.ul`
  display: none;
  flex-flow: wrap;
  @media screen and (min-width: 768px) {
    display: flex;
  }
`;
const CityLi = styled.li`
  width: calc(100% / 3);
  margin-bottom: 16px;
`;
const FollowLink = styled.a`
  flex: 0 0 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 24px;
  background: ${({ theme }) => theme.colors.TextBeta};
  &:last-child {
    margin-right: 0;
  }
  svg {
    width: 15px;
    fill: #fff;
  }
  ${({ iconKey }) => {
    switch (iconKey) {
      case 'facebook':
        return css`
          background: #3962a3;
        `;
      case 'twitter':
        return css`
          background: #4093c9;
        `;
      case 'linkedin':
        return css`
          background: #046e99;
        `;
      case 'youtube':
        return css`
          background: #d42506;
        `;
    }
  }}
  @media screen and (min-width: 768px) {
    flex: 0 0 50px;
    height: 50px;
    background: ${({ theme }) => theme.colors.Neutral[500]};
    svg {
      width: 20px;
      fill: ${({ theme }) => theme.colors.TextBeta};
    }
    &:hover {
      background: #171616;
      opacity: 0.9;
      svg {
        fill: #fff;
      }
    }
    &:visited,
    &:active,
    &:focus {
      opacity: 1;
    }
    ${({ iconKey }) => {
      switch (iconKey) {
        case 'facebook':
          return css`
            background: ${({ theme }) => theme.colors.Neutral[500]};
            &:hover {
              background: #3962a3;
            }
          `;
        case 'twitter':
          return css`
            background: ${({ theme }) => theme.colors.Neutral[500]};
            &:hover {
              background: #4093c9;
            }
          `;
        case 'linkedin':
          return css`
            background: ${({ theme }) => theme.colors.Neutral[500]};
            &:hover {
              background: #046e99;
            }
          `;
        case 'youtube':
          return css`
            background: ${({ theme }) => theme.colors.Neutral[500]};
            &:hover {
              background: #d42506;
            }
          `;
      }
    }}
  }
`;
const CopyRight = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.Neutral[300]};
  padding: 24px 0;
`;

const HideMobile = styled.div`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const VapFooter = () => {
  const currentYear = new Date().getFullYear();
  return (
    <FooterWrap>
      <RowFot>
        <LeftColumn md={8} sm={12} xs={12} className="pR">
          <FooterList className="dF fwW">
            {quikrLinks.map(link => {
              return (
                <Li key={link.key}>
                  <Link href={link.href}>{link.key}</Link>
                </Li>
              );
            })}
          </FooterList>
          <P tagName="p">
            Widely known as India’s no. 1 online classifieds platform, Quikr is all about you. Our
            aim is to empower every person in the country to independently connect with buyers and
            sellers online. We care about you — and the transactions that bring you closer to your
            dreams. Want to buy your first car? We’re here for you. Want to sell commercial property
            to buy your dream home? We’re here for you. Whatever job you’ve got, we promise to get
            it done.
          </P>
          <PostAdDiv>
            <span>At Quikr, you can buy, sell or rent anything you can think of.</span>
            <PostAd
              className="dIF"
              btnType="postAd"
              disabled={false}
              href="https://www.quikr.com/post-classifieds-ads"
              size="lg"
              type="button"
            >
              Post Free Ad
            </PostAd>
          </PostAdDiv>
          <Heading tagName="div" modifiers="bold">
            Terms &amp; Policy
          </Heading>
          <FooterList className="dF fwW">
            {quikrPolicies.map(policy => {
              return (
                <Li key={policy.key}>
                  <Link
                    href={policy.href}
                    target="_blank"
                    rel={policy.rel && 'noopener noreferrer'}
                  >
                    {policy.key}
                  </Link>
                </Li>
              );
            })}
          </FooterList>
          <HideMobile>
            <Heading tagName="div" modifiers="bold">
              Our Other Brands
            </Heading>
            <FooterList className="dF fwW">
              {quikrBrands.map(brand => {
                return (
                  <Li key={brand.key} brand={true}>
                    <Link href={brand.href} target="_blank" rel="noopener noreferrer">
                      {brand.key}
                    </Link>
                  </Li>
                );
              })}
            </FooterList>
          </HideMobile>
        </LeftColumn>
        <RightColumn md={4} sm={12} xs={12}>
          <CityUl>
            {footerCities.map(city => {
              return (
                <CityLi key={city}>
                  <Link
                    href={`//www.quikr.com/${city
                      .split(' ')
                      .join('-')
                      .toLowerCase()}`}
                  >
                    {city}
                  </Link>
                </CityLi>
              );
            })}
          </CityUl>
          <Heading tagName="div" modifiers="bold">
            Follow Us
          </Heading>
          <div className="dF">
            {socialLinks.map(link => {
              return (
                <FollowLink
                  className="dFA jcC"
                  target="_blank"
                  rel="noopener noreferrer"
                  iconKey={link.key}
                  key={link.key}
                  href={link.href}
                >
                  <SvgComponent svgSprite={footerSprite} id={link.icon} />
                  <span className="link-text-hidden">{link.key}</span>
                </FollowLink>
              );
            })}
          </div>
          <Heading tagName="div" modifiers="bold">
            Download The App
          </Heading>
          <div className="dF">
            {appLinks.map(link => {
              return (
                <FollowLink
                  className="dFA jcC"
                  target="_blank"
                  rel="noopener noreferrer"
                  iconKey={link.key}
                  key={link.key}
                  href={link.href}
                >
                  <SvgComponent svgSprite={footerSprite} id={link.icon} />
                  <span className="link-text-hidden">{link.key}</span>
                </FollowLink>
              );
            })}
          </div>
        </RightColumn>
      </RowFot>
      <CopyRight className="dF jcC fwM">
        Copyright © 2008 - {currentYear} Quikr India Private Limited
      </CopyRight>
    </FooterWrap>
  );
};

export default VapFooter;
